<script>
// import DatePicker from "vue2-datepicker";
import Layout from "../../layouts/main";
//import Multiselect from "vue-multiselect";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useListTable from '@/comp-functions/useListTable';

export default {
  page: {
    title: "Create Incoming",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    // DatePicker,
    //Multiselect,
    Layout,
    PageHeader,
  },

  setup () {
    const tableColumns = [
      //this.$t('fields.port.name')
      { key: "departure_date", label: 'Departure Date', sortable: true },
      { key: "vnd_vendorid", label: 'Airline', sortable: true },
      { key: "flight_no", label: "Filght No.", sortable: true },
      { key: "route", label: "Route", sortable: true },
      { key: "time", label: "Time", sortable: true },
      { key: "action",label: "#" }
    //  { key: "prt_timezoneid", label: "Time Zone", sortable: true },
    ]

     // Table Handlers
    const columnToBeFilter = [
      'vnd_vendorid',
      'flight_no'
    ]

     return {
      tableColumns,
      columnToBeFilter,
      ...useListTable({
        url: 'master/outstanding/incoming/schedule'
      }),
    }

  },
  data() {
    return {
      title: "Create Incoming",
      items: []
    };
    },
  };

</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <b-button href="/masterdata/port/add" variant="light">
              <i class="ri-add-line align-middle mr-2"></i> {{ $t('global.button.add') }}
            </b-button> -->
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select v-model="perPage" size="sm" :options="perPageOptions"></b-form-select>&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="searchQuery"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
             <div class="table-responsive mb-0">
              <b-table
                no-provider-paging
                no-provider-filtering
                :filter-included-fields="columnToBeFilter"
                :fields="tableColumns"
                :items="itemLists"
                :per-page="perPage"
                :current-page="currentPage"
                :filter="searchQuery"
                responsive
                primary-key="id"
                :sort-by.sync="sortBy"
                show-empty
                :busy="isBusy"
                empty-text="No matching records found"
                :sort-desc.sync="isSortDirDesc"
                class="position-relative"
              >

               <template v-slot:cell(action)>
                  <a href="add" class="text-primary" v-b-tooltip.hover title="Choose">
                    <i class="ri-file-text-line"></i>
                  </a>
                </template>

               <template #cell(route)="data">
                  {{ data.item.origin_port_id }} - {{ data.item.dest_port_id }}
                </template>

                <template #cell(time)="data">
                  {{ data.item.departure_time }} - {{ data.item.arrival_time }}
                </template>

                <template #cell(vnd_vendorid)="data">
                  {{ data.item.vendor.name }}
                </template>
                
                </b-table>
            </div>
            <!-- <div class="row">
              <div class="col-xl-10">
                <div class="row">
                  <div class="col-xl-3 text-center">
                    <h5>Tri-MG</h5>
                    <img src="@/assets/images/vendorlogo/trimglogo.png" width="120"/>
                  </div>
                  <div class="col-xl-2 text-center">
                    12:50<br/>
                    Jakarta<br/>
                    HLP
                  </div>
                  <div class="col-xl-3 text-center">
                    <i class="fas fa-plane"></i><br/>
                    18h 35m<br/>
                    2x Transit
                  </div>
                  <div class="col-xl-2 text-center">
                    09:25(+1d)<br/>
                    Jayapura<br/>
                    DJJ
                  </div>
                  
                  <div class="col-xl-2 text-center">
                    10 SMU<br/>
                    50 Collies
                  </div>
                </div>
              </div>
              <div class="col-xl-2">
                <b-button variant="primary" href="add">
                  <i class="ri-save-line align-middle ml-2"></i>
                  Choose
                </b-button>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>